<template>
  <div class="page bg-white" id="reorganize">
    <el-row v-if="treeData.length" v-loading="treeLoading" :gutter="20">
      <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3" v-for="item in treeData" :key="item.id">
        <div @click="gotoList(item)" class="itemdar">
          <el-image style="width: 100%;height: 180px" :src="url" :fit="'cover'"></el-image>
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
            <div class="omit">{{ item.name }}</div>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <div class="noDataBox" v-else>
      <img src="@/assets/img/noData.png" class="noData">
      <div>暂无藏品分类</div>
    </div>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[27, 54, 108]"
          :page-size="pageSize"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "ledger",
  data() {
    return {
      treeData: [],
      allTreeData: [],
      treeLoading: false,
      url: require('@/assets/img/ledger.png'),
      pageNo: 1,
      pageNo2: 0,
      pageSize: 27,
      total: 0,
    }
  },

  mounted() {
    let fanhui = this.$route.query.fanhui
    if (fanhui) {
      let ledgerPag = JSON.parse(sessionStorage.getItem('ledgerPag'))
      this.pageNo2 = ledgerPag.pageNo
      this.pageSize = ledgerPag.pageSize
      sessionStorage.removeItem('ledgerPag')
    }
    this.searchTree()
  },

  methods: {
    // 查询树状数据
    searchTree() {
      this.treeLoading = true
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.disassembleTree(res.data, '')
          let dadtldsa = JSON.parse(JSON.stringify(this.allTreeData))
          this.total = this.allTreeData.length
          if (this.pageNo2) {
            this.pageNo = this.pageNo2
            this.pageNo2 = 0
          }
          if (this.allTreeData.length >= this.pageNo * this.pageSize) {
            this.treeData = dadtldsa.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
          } else {
            this.treeData = dadtldsa.slice((this.pageNo - 1) * this.pageSize, this.allTreeData.length)
          }
        }
        this.treeLoading = false
      })
    },

    //树形结构拆开
    disassembleTree(data, name) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.$set(item, 'name', name + item.archivesBasicName)
          this.allTreeData.push(item)
          this.disassembleTree(item.children, item.name + '-')
        })
      } else {
        return
      }
    },

    //分类账列表
    gotoList(row) {
      let ledgerPag = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      sessionStorage.setItem('ledgerPag', JSON.stringify(ledgerPag))
      this.$router.push({path: '/collection/accounts/ledgerList', query: {archivesBasicDataId: row.id}})
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1
      this.getDataList()
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },

    getDataList() {
      this.treeData = []
      let dadtldsa = JSON.parse(JSON.stringify(this.allTreeData))
      if (this.allTreeData.length >= this.pageNo * this.pageSize) {
        let index1 = (this.pageNo - 1) * this.pageSize
        let index2 = this.pageNo * this.pageSize
        this.treeData = dadtldsa.slice(index1, index2)
      } else {
        this.treeData = dadtldsa.slice((this.pageNo - 1) * this.pageSize, this.allTreeData.length)
      }
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.itemdar {
  cursor: pointer;
  margin-bottom: 10px;
}

.noDataBox {
  height: calc(100% - 80px);
  padding-top: 5%;

  .noData {
    width: 20%;
  }

  width: 100%;
  text-align: center;
}
</style>